import Intercom from '@intercom/messenger-js-sdk';
import PropTypes from 'prop-types';

export default function AIChat({user_id, name, email, created_at}) {
  Intercom({
    app_id: process.env.REACT_APP_AI_CHAT_APP_ID,
    user_id, 
    name, 
    email, 
    created_at, 
  });

  AIChat.propTypes = {
    user_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
  };

  return <div>Example App</div>;
}