import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material/styles';
import DashboardNavbar from './DashboardNavbar';
import {
  Grid,
  Column,
  Content,
  Theme,
  GlobalTheme
} from '@carbon/react';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import VideoModalSection from './main/VideoModalSection';
import AIChat from './ai-chat/AIChat';
// import { useTranslation } from 'react-i18next';

// const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
//   backgroundColor: theme.palette.background.default,
//   display: 'flex',
//   height: '100%',
//   overflow: 'hidden',
//   width: '100%'
// }));

// const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
//   display: 'flex',
//   flex: '1 1 auto',
//   overflow: 'hidden',
//   paddingTop: '64px',
//   [theme.breakpoints.up('lg')]: {
//     paddingLeft: '0px'
//   }
// }));

// const DashboardLayoutContainer = experimentalStyled('div')({
//   display: 'flex',
//   flex: '1 1 auto',
//   overflow: 'hidden'
// });

// const DashboardLayoutContent = experimentalStyled('div')({
//   flex: '1 1 auto',
//   height: '100%',
//   overflow: 'auto',
//   position: 'relative',
//   WebkitOverflowScrolling: 'touch'
// });

const DashboardLayout = () => {
  // const [setIsSidebarMobileOpen] = useState(false);
  // const { t } = useTranslation();
  const {isFirstLogin, configSiteData: {tm}, user } = useAuth();

  return (

    // <DashboardLayoutRoot>
    //   <DashboardNavbar />
    //   {/* <DashboardSidebar */}
    //   {/*  onMobileClose={() => setIsSidebarMobileOpen(false)} */}
    //   {/*  openMobile={isSidebarMobileOpen} */}
    //   {/* /> */}
    //   <DashboardLayoutWrapper>
    //     <DashboardLayoutContainer>
    //       <DashboardLayoutContent>
    //         <Outlet />
    //       </DashboardLayoutContent>
    //     </DashboardLayoutContainer>
    //   </DashboardLayoutWrapper>
    // </DashboardLayoutRoot>

    <>
      <AIChat user_id={user?.sub} name={user?.given_name} email={user?.email} created_at={user?.created_at}/>
      <Theme theme="g100">
        <DashboardNavbar />
      </Theme>
      <Content>
        <Outlet />
        <div style={{
          justifySelf: 'center',
          marginTop: 'auto',
          paddingBottom: '2rem'
        }}>
          <span className='terms-of-service-text'>
            {tm['Footer terms of service']}
          </span>
        </div>
        <VideoModalSection isFirstLogin={isFirstLogin} email={user?.email}/>
      </Content>
      {/* <DashboardSidebar */}
      {/*  onMobileClose={() => setIsSidebarMobileOpen(false)} */}
      {/*  openMobile={isSidebarMobileOpen} */}
      {/* /> */}
    </>
  );
};

export default DashboardLayout;
